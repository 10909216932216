
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  MultiCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    MultiCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 3;
    const router = useRouter();

    const title = "What are the main reasons you would like to use the pill?";
    const subtitle = "Select all that applies to you.";
    const options = [
      {
        label: "Prevent pregnancy",
        value: "Prevent pregnancy",
      },
      {
        label: "Regulate my periods",
        value: "Regulate my periods",
      },
      {
        label: "Reduce menstrual cramps",
        value: "Reduce menstrual cramps",
      },
      {
        label: "Improve skin",
        value: "Improve skin",
      },
      {
        label: "Reduce excess hair",
        value: "Reduce excess hair",
      },
      {
        label: "Manage my polycystic ovary syndrome (PCOS)",
        value: "Manage my polycystic ovary syndrome (PCOS)",
      },
      {
        label: "Manage my endometriosis",
        value: "Manage my endometriosis",
      },
      {
        label: "Other",
        value: "NIL",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      questionNumber,
      title,
      subtitle,
    };
  },
});
